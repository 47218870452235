
































































































































  .welcome {
    font-size:26px;
  }
.forgotPass {
  float: right;
  padding-right: 7px;
  color: #1c9edb;
  text-decoration: underline;
  padding-top: 5px;
}

.login-view form {
  max-width: 350px;
  margin:0 auto;
}
.submitLogin {
  background-image: linear-gradient(270deg, #3ccdef 0%, #1c9edb 100%);
}
#registerText {
  color: #3f3356;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}
#registerLink, .link {
  color: #1c9edb;
  font-weight: 700;
  text-decoration: underline;
}
h4 {
  font-size: 24px;
  margin: 0rem 0rem;
  margin-top: -25px;
  color: #3f3356;
  font-weight: 700;
}
